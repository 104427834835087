import { Operation } from '@/models/operation.model';

export const extendWithAlarmTypeIcon = (op: Operation | undefined | null): Operation | null => {
  if (!op) {
    return null;
  }

  let icon = '';
  switch (op.alarmierungsart) {
    case 'Funkmelder':
      icon = 'mdi-radio-handheld';
      break;

    case 'Telefon':
      icon = 'mdi-phone';
      break;

    case 'O-Amt':
      icon = 'mdi-car-emergency';
      break;

    case 'direkt':
      icon = 'mdi-alarm-light';
      break;

    default:
      break;
  }

  return { alarmierungsartIcon: icon, ...op };
};
