






















































































































































import {
  computed,
  defineComponent,
  inject,
  onMounted,
  ref
} from '@vue/composition-api';
import { useActions, useGetters } from 'vuex-composition-helpers';

import { format, parseISO } from 'date-fns';

import { Operation } from '@/models/operation.model';
import {
  FETCH_ASSET,
  FETCH_IMAGE,
  GET_OPERATIONS
} from '@/store/operation.actions';

import { extendWithAlarmTypeIcon } from '@/helpers/alarmtype-mapping';
import VueRouter from 'vue-router';

import WatermarkOverlay from '@/components/WatermarkOverlay.vue';
import { sortCars, sortOrganisations } from '@/helpers/sort-entries';
import { isImg } from '@/helpers/image-entry-helpers';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';

export default defineComponent({
  name: 'OperationDetail',
  props: {
    id: {
      type: String
    }
  },
  components: {
    WatermarkOverlay,
    VuePdfEmbed
  },
  setup: props => {
    const getters = useGetters([GET_OPERATIONS]);
    const operations = getters[GET_OPERATIONS];

    const formatDate = (dateStr: string): string => {
      if (!dateStr || dateStr.length === 0) {
        return '';
      }

      const date = parseISO(dateStr);
      return date ? format(date, "dd.MM.yyyy 'um' HH:mm 'Uhr'") : '';
    };

    const id = props.id;
    const operation = (operations.value as Operation[]).find(
      op => op._id === id
    );

    const imgAssetUrls = ref([] as string[]);
    const pdfAssetUrls = ref([] as string[]);

    const actions = useActions([FETCH_IMAGE, FETCH_ASSET]);
    const fetchImageAction = actions[FETCH_IMAGE];
    const fetchAssetAction = actions[FETCH_ASSET];

    onMounted(async () => {
      if (!operation?.bilder || operation?.bilder.length === 0) {
        return;
      }

      operation?.bilder.forEach(async (assetId: string) => {
        const asset = await fetchAssetAction(assetId);
        if (!asset) {
          return;
        }

        // asset path starts with /
        if (isImg(asset.path.toLowerCase())) {
          const url = await fetchImageAction(assetId);
          imgAssetUrls.value.push(url);
        } else {
          const host = window.location.hostname;
          let fetchUrl: string;
          if (host.startsWith('localhost')) {
            fetchUrl = 'http://localhost:8001/cockpit';
          } else {
            fetchUrl = `http://${host}/cockpit`;
          }

          const url = `${fetchUrl}/storage/uploads${asset.path}`;

          pdfAssetUrls.value.push(url);
        }
      });
    });

    const carNames = computed(() =>
      operation?.fahrzeuge
        ? sortCars(operation.fahrzeuge).map(c => c.name)
        : ['-']
    );
    const organisationNames = computed(() =>
      operation?.einheiten
        ? sortOrganisations(operation.einheiten).map(c => c.name)
        : ['-']
    );

    const getUrl = (url: string | undefined) => {
      if (!url) {
        return '';
      }

      if (!url?.startsWith('http://') && !url?.startsWith('https://')) {
        return `http://${url}`;
      }
      return url;
    };

    const presseUrl1 = computed(() => {
      return getUrl(operation?.presseUrl1);
    });

    const presseUrl2 = computed(() => {
      return getUrl(operation?.presseUrl2);
    });

    const presseUrl3 = computed(() => {
      return getUrl(operation?.presseUrl3);
    });

    const operationWithIcon = computed(() =>
      extendWithAlarmTypeIcon(operation)
    );

    const router = inject<VueRouter>('$router');
    const goBack = () => router?.go(-1);
    const magnified = ref(false);

    return {
      operation,
      operationWithIcon,
      formatDate,
      imgAssetUrls,
      pdfAssetUrls,
      carNames,
      organisationNames,
      presseUrl1,
      presseUrl2,
      presseUrl3,
      goBack,
      magnified
    };
  }
});
